<template>
  <div>
    <div class="view" v-loading="loading" v-if="evaluationData.length">
      <div class="list" v-for="(item, index) in evaluationData" :key="index">
        <div class="inquirtEvaluation">
          <span class="left">
            {{ $t("enquiryEvaluation") }}：{{
              item.evaluation | inquiryEvaluation(commentStatus, LOCALE)
            }}
          </span>
          <span class="right"
            >{{ $t("Offeror") }}：{{
              item.offeror_type | evaluationType(identity, LOCALE)
            }}</span
          >
        </div>
        <div class="deliverGoods">
          <span>
            {{ $t("Willingnesstodeliver") }}：
            <el-rate class="rate" :value="item.intentions" disabled></el-rate>
          </span>
          <span>
            {{ $t("Informationauthenticity") }}：
            <el-rate
              class="rate"
              :value="item.validity"
              disabled
              text-color="#ff9900"
            ></el-rate>
          </span>
        </div>
        <p class="details">{{ item.feelings }}</p>
      </div>
    </div>
    <div v-else style="position:relative;height:100px">
      <h1>11111111111</h1>
      <noDataImg></noDataImg>
    </div>
  </div>
</template>

<script>
import { commentStatus, identity } from "@/baseUtils/enumValue";
// import noData from '@/baseComponents'
export default {
  name: "viewEvaluation",
  components: {},

  props: ["inquiryId","type"],
  data() {
    return {
      commentStatus,
      identity,
      evaluationData: [],
      loading: false,
    };
  },
  mounted() {
    console.log('1111111111111');
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      console.log(this.type);
        let result = await this.$store.dispatch(
          "baseConsole/inquiry_evaluationList",
          {
            inquiry_id: this.inquiryId,
            inquiry_type: this.type,
          }
        );
        if (result.success) {
          this.evaluationData = result.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
    },
  },
  filters: {
    evaluationType(type, data, locale) {
      let str = "";
      if (locale == "en") {
        str = data.find((item) => item.value == type).label_en;
      } else {
        str = data.find((item) => item.value == type).label_zh;
      }
      return str;
    },
    inquiryEvaluation(val, data, locale) {
      let str = "";
      if (locale == "en") {
        str = data.find((item) => item.value == val).label_en;
      } else {
        str = data.find((item) => item.value == val).label_zh;
      }
      return str;
    },
  },
};
</script>

<style scoped lang="less">
.view {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .list {
    margin-top: 24px;
    border-bottom: 1px solid rgba(232, 232, 232, 1);
    .rate {
      display: inline-block;
      position: relative;
      top: -2px;
    }
    .inquirtEvaluation {
      margin-bottom: 17px;
      .left {
        margin-right: 25px;
      }
    }
    .deliverGoods {
      margin-bottom: 15px;
      span:nth-child(1) {
        margin-right: 42px;
      }
    }
    .details {
      min-height: 72px;
      padding: 0 13px;
      padding-top: 16px;
      line-height: 20px;
      background: #f5f5f5;
      margin-bottom: 16px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        border: 14px solid #f5f5f5;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        top: -25px;
        left: 7px;
      }
    }
  }
  .list:nth-child(1) {
    margin-top: 0;
  }
}
</style>
