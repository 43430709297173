<template>
  <div class="myInquiryListSearch">
    <el-form
      :label-position="'right'"
      label-width="20px"
      :class="{ 'form-wrap': IS_EN }"
    >
      <div class="clearfix">
        <el-row>
          <el-col :span="6">
            <el-form-item label="">
              <el-input
                style="width:250px"
                :placeholder="$t('inquiryNumber')"
                v-model="queryParams.inquiry_number"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 状态 发盘人 ---> 
            <el-form-item label="">
              <el-input
                style="width:250px"
                :placeholder="$t('server_company_name')"
                v-model="queryParams.company_name"
                clearable
                size="small"
              ></el-input>
            </el-form-item>

            
          </el-col>
          <el-col :span="6">
              <el-form-item label="">
              <el-input
                style="width:250px"
                :placeholder="$t('cargoName')"
                v-model="queryParams.cargo_name"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="min-width:203px" class="tr top3" :class="{ updown: IS_ZH }">
            <el-button
              type="default"
              @click="changeOpenStatus(!isOpen)"
              size="small"
              class="openAndClose top1"
              plain
            >
              <i class="el-icon-plus" v-if="!isOpen"></i>
              <i class="el-icon-minus" v-else></i>
            </el-button>
            <el-button type="primary" size="small" @click="search">{{$t('search')}}</el-button>
            <el-button  size="small" @click="Resert">{{$t('reset')}}</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="clearfix" v-show="isOpen">
        <el-row >
          <el-col :span="6">
            <el-form-item label="">
              <el-select
                style="width:250px"
                :disabled="USER_INFO.is_admin==0"
                :placeholder="$t('Issuer')"
                v-model="queryParams.user_id"
                size="small"
                clearable
                
              >
                <el-option
                  v-for="item in userArr"
                  :key="item.key"
                  :value="item.user_id"
                  :label="IS_EN ? item.name_en : item.name_zh"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <CityCompleteWithCountry
                style="width:250px"
                v-model="queryParams.from_region"
                :placeTitle="$t('departure')"
                :clearable="true"
              ></CityCompleteWithCountry>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="">
              <CityCompleteWithCountry
                style="width:250px"
                v-model="queryParams.arrival_region"
                :placeTitle="$t('destination')"
                :clearable="true"
              ></CityCompleteWithCountry>
            </el-form-item>
          </el-col>
          
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  distributionMethod,
  inOrOut,
  transportType,
} from "@/baseUtils/enumValue";
import CityCompleteWithCountry from "@/baseComponents/CityCompleteWithCountry";
export default {
  components: { CityCompleteWithCountry, distributionMethod },
  data() {
    return {
      input: "",
      isOpen: false,
      departure: "",
      arrival: "",
      queryParams: {
        inquiry_number: "",
        cargo_name: "",
        from_region: "",
        arrival_region: "",
        distribute_way: "",
        port:"",
        import_export_way:"",
        transport_mode:"",
        user_id:"",
        company_name:""
      },
      distributionMethod,
      inOrOut,
      transportType,
      userArr:[]
    };
  },
  props: {
    initParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inquiryType: {
      type: Number,
      default: 1,
    },
  },
  async created() {
    console.log('fff',this.USER_INFO,this.USER_INFO.is_admin);
    if(this.USER_INFO.is_admin==0){
      this.queryParams.user_id=this.USER_INFO.id
    }
    this.queryParams = Object.assign(this.queryParams, this.initParams);
    let params={
      company_id:this.USER_INFO.company_id,
      nopage:1,
      type:0
    }
    let res=await this.$store.dispatch('baseConsole/company_accountManage',params );
    this.userArr=res.data
    console.log('--666----',res);
  },
  methods: {
    Resert(){
      this.queryParams={
        inquiry_number: "",
        cargo_name: "",
        from_region: "",
        arrival_region: "",
        distribute_way: "",
        port:"",
        import_export_way:"",
        transport_mode:""
      }
    },
    search() {
      this.$emit('inquirySearch', this.queryParams);
      // this.$store.commit(
      //   "inquiry/SET_INQUIRY_LIST_SEARCH_HEADER",
      //   this.queryParams
      // );
      // if (this.inquiryType != 2) {
      //   this.$GLOBALEVENT.$emit("MYINQUIRYLISTSEARCH");
      // } else {
      //   this.$GLOBALEVENT.$emit("CUSTOMSDETAIL");
      // }
    },
    changeOpenStatus(status) {
      this.isOpen = status;
    },
  },
};
</script>

<style scoped lang="less">
.myInquiryListSearch {
  padding: 20px 0;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  .form-wrap {
    position: relative;
    left: -10px;
  }

  .el-form-item {
    margin-bottom: 15px;
    width: 330px;
    .el-select {
      width: 100%;
    }
  }
  .updown {
    padding-right: 20px;
  }
}
</style>
