<template>
  <div>
    <el-autocomplete
      v-model="city_value"
      suffix-icon="el-icon-search"
      :placeholder="placeTitle"
      :size="size"
      :fetch-suggestions="queryCity"
      @select="handleSelect"
      @blur="handleBlur"
      :clearable="clearable"
      :disabled="disabled"
    >
      <template slot-scope="{ item }">
        <div class="name textOverflow" :title="item.title">
          {{ item.value }}
          <span class="desc"
            >&nbsp;&nbsp;{{ item.country_id | countryFormat(_locale) }}</span
          >
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import { getCountryNameById } from "~/baseUtils/AddressConfig";
import { CITYLIST } from "~/baseUtils/AddressConfig/cityList";
export default {
  props: {
    placeTitle: {
      type: String,
      default: "City",
    },
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this._locale = this.locale || this.LOCALE;
    this.city_value = this.value;
  },
  data() {
    return {
      city_value: null,
      cityList: [],
      isEmit: false,
      _locale: "",
    };
  },
  methods: {
    queryCity(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      let list = CITYLIST.filter((item) => {
        return (
          item[`name_${this._locale}`]
            .toLowerCase()
            .replace(/\s/g, "")
            .indexOf(queryString.toLowerCase().replace(/\s/g, "")) === 0
        );
      });
      let _list = list.map((item) => {
        return {
          value: item[`name_${this._locale}`],
          country_id: item.country_id,
          title:
            item[`name_${this._locale}`] +
            " - " +
            getCountryNameById(item.country_id, this._locale),
        };
      });
      cb(_list);
    },
    handleSelect(item) {
      this.$emit("countryChange", item.country_id);
    },
    handleBlur() {
      if (!this.city_value) {
        return;
      }
      let current = CITYLIST.find((item) => {
        return (
          item[`name_${this._locale}`].toLowerCase().replace(/\s/g, "") ===
          this.city_value.toLowerCase().replace(/\s/g, "")
        );
      });
      if (current) {
        this.$emit("countryChange", current.country_id);
      }
    },
  },
  watch: {
    value(nv) {
      this.city_value = nv;
    },
    city_value(nv) {
      this.$emit("input", nv);
    },
  },
};
</script>

<style scoped lang="less">
.el-autocomplete {
  width: 100%;
  .el-input__icon {
    color: #c0c4cc;
  }
}
.desc {
  color: #999;
  font-size: 12px;
}
</style>